
import {
  computed, defineComponent, PropType, toRefs,
} from 'vue';

import TTPrompt from '@uikit/components/TTPrompt/TTPrompt.vue';
import AtomCover from '@/components/ui/AtomCover/AtomCover.vue';

import { useI18n } from '@/plugins/vue-i18n';

import { UUID } from '@/domains/common';
import { AtomType } from '@/domains/atom';

export default defineComponent({
  name: 'ProgramCardContinueItem',
  components: {
    TTPrompt,
    AtomCover,
  },
  props: {
    id: {
      type: String as PropType<UUID>,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    finishedStepsCount: {
      type: Number,
      default: 0,
    },
    totalStepsCount: {
      type: Number,
      default: 0,
    },
    atomType: {
      type: String as PropType<AtomType>,
      default: '',
    },
  },
  setup(props) {
    const { tc } = useI18n();

    const {
      finishedStepsCount,
      totalStepsCount,
    } = toRefs(props);

    const percent = computed(() => Math.trunc((finishedStepsCount.value * 100) / totalStepsCount.value));
    const lessonsCountOf = computed(() => tc('pluralized.lessonsCountOf', totalStepsCount.value, {
      count: finishedStepsCount.value,
      total: totalStepsCount.value,
    }));

    const isAtomProgressVisible = computed(() => [AtomType.TRACK].includes(props.atomType));

    return {
      percent,
      lessonsCountOf,
      isAtomProgressVisible,
    };
  },
});
