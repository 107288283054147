
import {
  computed, defineComponent, PropType, ref, toRefs,
} from 'vue';

import TTPrompt from '@uikit/components/TTPrompt/TTPrompt.vue';
import AssignedTracksCardDeadline from '@/components/tracks/AssignedTracks/AssignedTracksCardDeadline.vue';
import AtomRecommendationChip from '@/components/catalogue/CatalogueAtomCard/AtomRecommendationChip.vue';
import AtomTypeChip from '@/components/catalogue/CatalogueAtomCard/AtomTypeChip.vue';
import AtomCover from '@/components/ui/AtomCover/AtomCover.vue';

import { UUID } from '@/domains/common';
import { AtomType } from '@/domains/atom';

import { useDayJs } from '@/plugins/dayjs/composables';
import { useI18n } from '@/plugins/vue-i18n';

export default defineComponent({
  name: 'AssignedTracksCard',
  components: {
    AssignedTracksCardDeadline,
    AtomRecommendationChip,
    AtomTypeChip,
    AtomCover,
    TTPrompt,
  },
  props: {
    catalogueAtomVersionId: {
      type: String as PropType<UUID>,
      default: '',
    },
    name: {
      type: String as PropType<string>,
      default: '',
    },
    atomType: {
      type: String as PropType<AtomType>,
      required: true,
    },
    finishDate: {
      type: String as PropType<string>,
      default: '',
    },
    finishedStepsCount: {
      type: Number as PropType<number>,
      default: 0,
    },
    totalStepsCount: {
      type: Number as PropType<number>,
      default: 0,
    },
    isRecommended: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const dayJs = useDayJs();
    const { tc } = useI18n();

    const {
      finishDate,
      finishedStepsCount,
      totalStepsCount,
      atomType,
    } = toRefs(props);

    const options = ref({ size: '320x320' });

    const isCompleted = computed(() => totalStepsCount.value - finishedStepsCount.value <= 0);
    const isFailed = computed(() => {
      const today = dayJs();
      const deadline = dayJs(finishDate.value);

      return !isCompleted.value && today.isAfter(deadline, 'days');
    });
    const progress = computed(() => Math.trunc((finishedStepsCount.value * 100) / totalStepsCount.value));
    const progressColor = computed(() => {
      if (isFailed.value) {
        return 'tt-light-mono-24';
      }

      if (isCompleted.value) {
        return 'tt-light-green vibrant';
      }

      return 'tt-light-blue vibrant';
    });
    const completedOf = computed(() => tc('pluralized.lessonsCountOf', totalStepsCount.value, {
      count: finishedStepsCount.value,
      total: totalStepsCount.value,
    }));
    const isTrack = computed(() => atomType.value === AtomType.TRACK);

    const onClickHandler = () => {
      emit('click');
    };

    return {
      isCompleted,
      isFailed,
      progress,
      progressColor,
      completedOf,
      isTrack,
      options,
      onClickHandler,
    };
  },
});
