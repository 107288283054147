
import {
  computed, defineComponent, PropType, ref, toRefs,
} from 'vue';
import { useI18n } from '@/plugins/vue-i18n';

import { UUID } from '@/domains/common';
import { IContentProvider } from '@/domains/content-provider';
import { ButtonState } from '../PlusButton/types';

import ProvidersChip from '@/components/ui/ProvidersChip';
import PlusButton from '@/components/tracks/PlusButton';
import AtomCover from '@/components/ui/AtomCover/AtomCover.vue';

import Names from '@/plugins/vue-router/routeNames';
import { AtomType } from '@/domains/atom';

export default defineComponent({
  name: 'CatalogueLearningProgram',

  components: {
    ProvidersChip,
    PlusButton,
    AtomCover,
  },

  props: {
    id: {
      type: String as PropType<UUID>,
      required: true,
    },
    name: {
      type: String,
      default: '',
    },
    duration: {
      type: Number,
      default: 0,
    },
    contentProviders: {
      type: Array as PropType<IContentProvider[]>,
      default: () => [],
    },
    isAssign: {
      type: Boolean,
      default: false,
    },
    isRecommended: {
      type: Boolean,
      default: false,
    },
    atomType: {
      type: String as PropType<AtomType>,
      required: true,
    },
  },

  setup(props, { emit }) {
    const { t } = useI18n();

    const {
      id,
      duration,
      isAssign,
    } = toRefs(props);

    const options = ref({ size: '320x320' });

    const durationHours = computed(() => {
      if (duration.value > 60) {
        return Math.trunc(duration.value / 60);
      }

      return 0;
    });
    const durationHoursString = computed(() =>
      t('CatalogueLearningProgram.humanizedDuration.hours', { time: durationHours.value }));
    const durationRemainingMinutesString = computed(() => {
      const remainingMinutes = duration.value - 60 * durationHours.value;

      if (remainingMinutes > 0) {
        return t('CatalogueLearningProgram.humanizedDuration.minutes', { time: remainingMinutes });
      }

      return '';
    });
    const durationStr = computed(() => {
      if (durationHours.value) {
        return `${durationHoursString.value} ${durationRemainingMinutesString.value}`;
      }

      return t('CatalogueLearningProgram.humanizedDuration.minutes', { time: duration.value });
    });
    const routeTo = computed(() => ({
      name: Names.R_APP_LEARNING_ATOM_PREVIEW,
      params: {
        catalogueAtomId: id.value,
      },
    }));
    const dataTestValue = computed(() => `track-${id.value}-${isAssign.value ? 'checked' : 'unchecked'}`);
    const plusButtonInitialState = computed(() => (isAssign.value ? ButtonState.CHECKED : ButtonState.DEFAULT));

    const onClickHandler = () => {
      emit('click:item', { id: id.value });
    };

    const onAssignClickHandler = (resolvePromise: Function) => {
      emit('click:assign', { id: id.value, isAssign: isAssign.value, resolvePromise });
    };

    return {
      options,
      durationHours,
      durationHoursString,
      durationRemainingMinutesString,
      durationStr,
      routeTo,
      dataTestValue,
      plusButtonInitialState,
      onClickHandler,
      onAssignClickHandler,
    };
  },
});
