
import {
  defineComponent, PropType, ref, computed, toRefs, watch,
} from 'vue';
import AssignedTracksCard from '@/components/tracks/AssignedTracks/AssignedTracksCard.vue';
import { IAssignedTrack } from '@/domains/track';

// Количество карточек в строке
const DEFAULT_ROW_CARDS_COUNT: number = 4;
// Количество карточек, показанных по умолчанию
const DEFAULT_VISIBLE_CARDS_COUNT: number = DEFAULT_ROW_CARDS_COUNT * 2;

export default defineComponent({
  name: 'AssignedTracks',
  components: {
    AssignedTracksCard,
  },

  props: {
    items: {
      type: Array as PropType<IAssignedTrack[]>,
      default: () => [],
    },
  },

  setup(props, { emit }) {
    const { items } = toRefs(props);

    const visibleCards = ref<number>(DEFAULT_VISIBLE_CARDS_COUNT);

    const restOfCards = computed(() => items.value?.length - visibleCards.value);
    const canShowCards = computed(() => {
      return restOfCards.value >= DEFAULT_ROW_CARDS_COUNT
        ? DEFAULT_ROW_CARDS_COUNT
        : restOfCards.value;
    });
    const visibleItems = computed(() => items.value?.slice(0, visibleCards.value));
    const localeParams = computed(() => ({
      count: canShowCards.value,
      total: restOfCards.value,
    }));

    const onClickItemHandler = (item: IAssignedTrack) => {
      emit('click:item', item);
    };
    const showMore = () => {
      if (restOfCards.value <= DEFAULT_ROW_CARDS_COUNT) {
        visibleCards.value += restOfCards.value;
      } else {
        visibleCards.value += DEFAULT_ROW_CARDS_COUNT;
      }
    };

    watch(items, (newValue) => {
      if (newValue?.length <= 8) {
        visibleCards.value = newValue?.length;
      } else {
        visibleCards.value = DEFAULT_ROW_CARDS_COUNT;
      }
    });

    return {
      restOfCards,
      canShowCards,
      visibleItems,
      localeParams,
      onClickItemHandler,
      showMore,
    };
  },
});
