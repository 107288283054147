
import { defineComponent, PropType } from 'vue';
import DialogComponent from '@/components/ui/DialogComponent';
import WillStudyItem from './WillStudyItem.vue';
import { IWillStudyTrack } from '@/domains/track';

const dialogActions = {
  OPEN: 'OPEN',
  CLOSE: 'CLOSE',
};

export default defineComponent({
  name: 'WillStudy',
  components: {
    WillStudyItem,
    DialogComponent,
  },

  props: {
    items: {
      type: Array as PropType<IWillStudyTrack[]>,
      default: () => [],
    },
    defaultVisibleItemsCount: {
      type: Number,
      default: 3,
    },
  },

  setup(_, { emit }) {
    const onClickItemHandler = (item: IWillStudyTrack) => {
      emit('click:item', {
        catalogueAtomMainId: item.catalogueAtomMainId,
        assignmentUserId: item.assignmentUserId,
        playerSessionId: item.playerSessionId,
        atomType: item.atomType,
      });
    };

    return {
      onClickItemHandler,
    };
  },

  data() {
    return {
      deletedItem: null as IWillStudyTrack | null,
      visibleStartIndex: 0,
    };
  },
  computed: {
    visibleItems(): IWillStudyTrack[] {
      return this.items.slice(
        this.visibleStartIndex,
        this.visibleStartIndex + this.defaultVisibleItemsCount,
      );
    },
    maxIndex(): number {
      const index = (this.items.length - 1) > 0 ? (this.items.length - 1) : 0;
      return Math.trunc(index / this.defaultVisibleItemsCount) * this.defaultVisibleItemsCount;
    },
  },
  methods: {
    previewItems() {
      if (this.visibleStartIndex <= 0) {
        return;
      }

      this.visibleStartIndex -= this.defaultVisibleItemsCount;
    },
    nextItems() {
      if (this.visibleStartIndex + this.defaultVisibleItemsCount >= this.items.length) {
        return;
      }

      this.visibleStartIndex += this.defaultVisibleItemsCount;
    },
    isActiveDeleteIcon(item: IWillStudyTrack): boolean {
      return this.deletedItem ? item.catalogueAtomMainId === this.deletedItem.catalogueAtomMainId : false;
    },
    removeItem(item: IWillStudyTrack) {
      this.deletedItem = item;
      this.callDialogAction(dialogActions.OPEN);
    },

    syncVisibleStartIndex() {
      if (this.visibleItems.length === 1) {
        this.visibleStartIndex -= this.defaultVisibleItemsCount;
      }
    },

    async confirmDialog() {
      if (this.deletedItem) {
        this.$emit('remove', this.deletedItem.assignmentUserId);
        this.syncVisibleStartIndex();
        this.callDialogAction(dialogActions.CLOSE);
        this.deletedItem = null;
      }
    },
    rejectDialog() {
      this.callDialogAction(dialogActions.CLOSE);
      this.deletedItem = null;
    },
    callDialogAction(action: string) {
      if (action === dialogActions.OPEN) (this.$refs.dialogRemove as any).open();
      if (action === dialogActions.CLOSE) (this.$refs.dialogRemove as any).close();
    },
  },
});
