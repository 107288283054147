
import { defineComponent } from 'vue';
import { IAtomWithAssigments } from '@/domains/track';
import { IAssignment } from '@/domains/assignment';
import { UUID } from '@/domains/common';

import UpButton from '@/components/ui/UpButton.vue';
import WillStudy from '@/components/tracks/WillStudy/WillStudy.vue';
import ProgramCardsContinue from '@/components/tracks/ProgramCardsContinue/ProgramCardsContinue.vue';
import AssignedTracks from '@/components/tracks/AssignedTracks/AssignedTracks.vue';
import CatalogueLearningPrograms from '@/components/tracks/CatalogueLearningPrograms/CatalogueLearningPrograms.vue';
import { CatalogueLearningFinishedAssignments } from '@/components/tracks/CatalogueLearningFinishedAssignments';

import * as LxpAssignmentService from '@/services/api/lxp-assignment';
import { userAssignmentsGet, userTrackAssign, userTrackUnassign } from '@/services/api/lxp-assignment';
import { userTracksGet } from '@/services/api/catalogue';
import { AtomType } from '@/domains/atom';
import { useFeatureFlag } from '@/plugins/feature-flags';
import { noop } from '@/helpers';

interface Data {
  assignmentsInProgress: IAssignment[];
  adminAssignments: IAssignment[];
  selfAssignments: IAssignment[];
  finishedAssignments: IAssignment[];
  atoms: IAtomWithAssigments[];
  learningProgramslength: number;
  isLoading: boolean;
}

// TODO: добавить заглушку на страницу для случаев когда один из обязательных запросов упал
export default defineComponent({
  name: 'LearningTracks',
  components: {
    WillStudy,
    UpButton,
    ProgramCardsContinue,
    AssignedTracks,
    CatalogueLearningPrograms,
    CatalogueLearningFinishedAssignments,
  },

  inject: ['Names'],

  setup() {
    const isAtomPublicationAvailable = useFeatureFlag('atom_publication');
    const isNewCourseAvailable = useFeatureFlag('atom_publication_course');

    return {
      isAtomPublicationAvailable,
      isNewCourseAvailable,
    };
  },

  data(): Data {
    return {
      assignmentsInProgress: [],
      adminAssignments: [],
      selfAssignments: [],
      finishedAssignments: [],
      atoms: [],
      learningProgramslength: 0,
      isLoading: true,
    };
  },

  computed: {
    hasAssignments(): boolean {
      return (
        this.assignmentsInProgress.length > 0
        || this.adminAssignments.length > 0
        || this.selfAssignments.length > 0
        || this.finishedAssignments.length > 0
      );
    },

    hasFinishAssignments(): boolean {
      return this.finishedAssignments.length > 0;
    },

    assignments(): IAssignment[] {
      return [
        ...this.adminAssignments,
        ...this.assignmentsInProgress,
        ...this.selfAssignments,
        ...this.finishedAssignments,
      ];
    },

    catalogueLearningPrograms(): IAtomWithAssigments[] {
      return this.atoms.map((item: IAtomWithAssigments) => ({
        ...item,
        isAssign: item.assignments && item.assignments.length > 0,
      }));
    },

    willStudyAssignments(): IAssignment[] {
      return this.selfAssignments;
    },

    appointedAssignments(): IAssignment[] {
      return this.adminAssignments;
    },

    availableAtomTypes(): AtomType[] {
      return this.isAtomPublicationAvailable
        ? [AtomType.QUIZ, AtomType.MULTILEVELTEST, AtomType.MULTICONTENT, AtomType.SCORM, AtomType.TRACK]
        : [AtomType.TRACK];
    },
  },

  created() {
    this.fetch();
  },

  methods: {
    async unassignAssignment(assignmentUserId: number) {
      try {
        await userTrackUnassign(assignmentUserId);
        this.$di.notify.success({ content: this.$t('LearningTracks.successes.unassign') });

        await this.fetch();
      } catch (e) {
        console.error(e);
        this.$di.notify.error({ content: this.$t('LearningTracks.errors.unassign') });
      }
    },

    async fetch() {
      // Отобразит страницу только после загрузки обязательных данных (и списка назначений и программ)
      // из каталога, чтобы определить какие программы из каталога уже назначены
      return Promise.all([this.fetchAssignments(), this.fetchTracks()]).finally(() => {
        this.isLoading = false;
      });
    },

    async fetchAssignments() {
      try {
        const assignments = await userAssignmentsGet({ params: { atomType: this.availableAtomTypes } });

        this.assignmentsInProgress = assignments.assignmentsInProgress;
        this.adminAssignments = assignments.adminAssignments;
        this.selfAssignments = assignments.selfAssignments;
        this.finishedAssignments = assignments.finishedAssignments;
      } catch (e) {
        console.error(e);
        this.$di.notify.error({ content: this.$t('LearningTracks.errors.assignments') });
      }
    },

    async fetchTracks() {
      try {
        const { atoms, pagination } = await userTracksGet({
          params: {
            limit: 3,
            atomNames: this.availableAtomTypes,
          },
        });
        this.atoms = atoms;
        this.learningProgramslength = pagination.total;
      } catch (e) {
        console.error(e);
        this.$di.notify.error({ content: this.$t('LearningTracks.errors.tracks') });
      }
    },

    async assignTrack({ id, _isAssign, resolvePromise }: { id: UUID; _isAssign: boolean; resolvePromise: Function }) {
      const track = this.atoms.find((currentTrack) => currentTrack.id === id);

      if (!track) return;

      try {
        await userTrackAssign({ catalogueAtomId: track.id });
        resolvePromise();

        await this.fetch();
      } catch (e) {
        console.error(e);
        this.$di.notify.error({ content: this.$t('LearningTracks.errors.assign') });
      }
    },

    async getPlayerSessionId(assignmentUserId: number, catalogueAtomId: UUID) {
      try {
        const response = await LxpAssignmentService.userAssignmentRun({
          assignmentUserId,
          data: { catalogueAtomMainId: catalogueAtomId },
        });
        return response.playerSessionId;
      } catch (error) {
        console.error(error);
        this.$di.notify.error({ content: this.$t('LearningTracks.errors.assignmentRun') });
      }
      return NaN;
    },

    isTrackInProgress(trackTotal: number, trackCompleted: number) {
      return !(trackCompleted === 0 || trackCompleted >= trackTotal);
    },

    async goToPage(item: {
      catalogueAtomMainId: UUID;
      assignmentUserId: number;
      playerSessionId: number;
      atomType: AtomType;
    }) {
      if (
        this.isAtomPublicationAvailable
        && (item.atomType !== AtomType.TRACK
          || (item.atomType === AtomType.TRACK && this.isNewCourseAvailable)
        )) {
        if (item.playerSessionId) {
          return this.$router.push({
            name: this.Names.R_APP_LEARNING_SESSION_GATEWAY,
            params: {
              playerSessionId: String(item.playerSessionId),
              atomType: String(item.atomType),
            },
            query: {
              // TODO: Удалить assignmentUserId вместе с удалением флага atom_publication_course
              assignmentUserId: String(item.assignmentUserId),
            },
          }).catch(noop);
        }

        return this.$router.push({
          name: this.Names.R_APP_LEARNING_ASSIGNMENT_RUN,
          params: {
            assignmentId: String(item.assignmentUserId),
            catalogueAtomMainId: String(item.catalogueAtomMainId),
          },
        }).catch(noop);
      }

      const { assignments } = this;

      const atom = assignments.find(
        (t) => t.catalogueAtomMainId === item.catalogueAtomMainId && t.assignmentUserId === item.assignmentUserId,
      );

      if (atom) {
        const isRouteOnSteps = this.isTrackInProgress(
          atom.trackProgress.totalStepsCount ?? 0,
          atom.trackProgress.finishedStepsCount ?? 0,
        );

        let { playerSessionId: atomPlayerSessionId } = atom;

        if (!Number.isFinite(item.playerSessionId)) {
          atomPlayerSessionId = await this.getPlayerSessionId(
            atom.assignmentUserId,
            atom.catalogueAtomMainId,
          );
        }

        if (Number.isFinite(atomPlayerSessionId)) {
          this.$router.push({
            name: isRouteOnSteps
              ? this.Names.R_APP_LEARNING_ASSIGNMENT_TRACK_STEP
              : this.Names.R_APP_LEARNING_ASSIGNMENT_TRACK_VIEW,
            params: {
              assignmentId: String(atom.assignmentUserId),
              trackSessionId: String(atomPlayerSessionId),
              playerSessionId: String(atomPlayerSessionId),
              atomType: AtomType.TRACK,
            },
          });
        }
      }
      return false;
    },

    async goToPreview({ catalogueAtomId }: { catalogueAtomId: UUID }) {
      if (this.isAtomPublicationAvailable) {
        return this.$router.push({
          name: this.Names.R_APP_LEARNING_CATALOGUE_ATOM_PREVIEW,
          params: {
            catalogueAtomId,
          },
        });
      }

      return this.$router.push({
        name: this.Names.R_APP_LEARNING_ATOM_PREVIEW,
        params: {
          catalogueAtomId,
        },
      });
    },

    async goToView({
      assignmentUserId,
      playerSessionId,
      atomType,
    }: {
      assignmentUserId: number;
      playerSessionId: number;
      atomType: AtomType;
    }) {
      if (this.isAtomPublicationAvailable) {
        return this.$router.push({
          name: this.Names.R_APP_LEARNING_SESSION_PLAYER_ATOM_PREVIEW,
          params: {
            playerSessionId: String(playerSessionId),
            atomType,
          },
        });
      }

      return this.$router.push({
        name: this.Names.R_APP_LEARNING_ASSIGNMENT_TRACK_VIEW,
        params: {
          assignmentId: String(assignmentUserId),
          trackSessionId: String(playerSessionId),
        },
      });
    },

    async onAtomCardClickHandler({ id: catalogueAtomId }: { id: UUID }) {
      const atom = this.atoms.find((item) => item.id === catalogueAtomId);

      if (!atom) {
        console.error(`Атома с идентификатором "${catalogueAtomId}" не существует`);

        return;
      }

      if (atom.assignments && atom.assignments.length > 0 && atom.assignments[0].assignmentUserId) {
        const { assignmentUserId } = atom.assignments[0];

        const { playerSessionId, atomType } = await LxpAssignmentService.userAssignmentRun({
          assignmentUserId,
          data: {
            catalogueAtomMainId: catalogueAtomId,
          },
        });

        if (assignmentUserId && playerSessionId) {
          this.goToView({ assignmentUserId, playerSessionId, atomType });
        }
      } else {
        this.goToPreview({ catalogueAtomId });
      }
    },

    async onFinishedAssginmentClickHandler({
      assignmentUserId,
      playerSessionId,
      atomType,
    }: {
      assignmentUserId: number;
      playerSessionId: number;
      atomType: AtomType;
    }) {
      this.goToView({
        assignmentUserId,
        playerSessionId,
        atomType,
      });
    },
  },
});
