
import Vue from 'vue';

// TODO: Добавить сторис и тесты к компоненту AssignedTracksCardDeadline

interface IInfo {
  value: boolean,
  icon: string,
  color: {
    icon: string,
    text: string,
  },
  text: string,
}

export default Vue.extend({
  name: 'AssignedTracksCardDeadline',
  props: {
    finishDate: {
      type: String,
      default: '',
    },
    finishedStepsCount: {
      type: Number,
      default: 0,
    },
    totalStepsCount: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    deadlineHumanized(): string {
      const deadline = this.$dayjs(this.finishDate);

      if (deadline.isBefore(this.$dayjs())) {
        return this.$t('AssignedTracksCardDeadline.failed');
      }

      if (deadline.isToday()) {
        return this.$t('AssignedTracksCardDeadline.completeToday');
      }

      if (deadline.isTomorrow()) {
        return this.$t('AssignedTracksCardDeadline.completeTomorrow');
      }

      return this.$t('AssignedTracksCardDeadline.completeBefore', { date: deadline.format('DD MMMM') });
    },
    isExpires(): boolean {
      const today = this.$dayjs();
      const deadline = this.$dayjs(this.finishDate);
      return deadline.diff(today, 'week') < 1;
    },
    isCompleted(): boolean {
      if (this.totalStepsCount || this.finishedStepsCount) {
        return this.totalStepsCount - this.finishedStepsCount <= 0;
      }
      return false;
    },
    isFailed(): boolean {
      const today = this.$dayjs();
      const deadline = this.$dayjs(this.finishDate);
      return !this.isCompleted && today.isAfter(deadline, 'days');
    },
    info(): IInfo {
      const resolver: IInfo[] = [
        {
          value: this.isFailed,
          icon: 'fas fa-exclamation-circle',
          color: {
            icon: 'tt-light-red',
            text: 'tt-light-red--text',
          },
          text: this.$t('AssignedTracksCardDeadline.failed'),
        },
        {
          value: this.isCompleted,
          icon: 'fas fa-check-circle',
          color: {
            icon: 'tt-light-green vibrant',
            text: 'tt-light-mono-64--text',
          },
          text: this.$t('AssignedTracksCardDeadline.completed'),
        },
        {
          value: this.isExpires,
          icon: 'fas fa-calendar-alt',
          color: {
            icon: 'tt-light-red',
            text: 'tt-light-red--text',
          },
          text: this.deadlineHumanized,
        },
        {
          value: true,
          icon: 'fas fa-calendar-alt',
          color: {
            icon: 'tt-light-mono-24',
            text: 'tt-light-mono-100--text',
          },
          text: this.deadlineHumanized,
        },
      ];

      return resolver.find((i) => i.value) as IInfo;
    },
  },
});
