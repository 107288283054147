
import {
  defineComponent, toRefs, unref, PropType,
} from 'vue';
import { AtomType } from '@/domains/atom';
import AtomCover from '@/components/ui/AtomCover/AtomCover.vue';

export default defineComponent({
  name: 'CatalogueLearningFinishedAssignment',

  components: {
    AtomCover,
  },

  props: {
    name: {
      type: String,
      required: true,
    },

    imageId: {
      type: String,
      default: null,
    },

    assignmentUserId: {
      type: Number,
      required: true,
    },

    playerSessionId: {
      type: Number,
      required: true,
    },

    atomType: {
      type: String as PropType<AtomType>,
      required: true,
    },
  },

  setup(props, { emit }) {
    const {
      assignmentUserId, playerSessionId, atomType,
    } = toRefs(props);

    const onCardClickHandler = () => {
      // TODO: Переделать на ссылку
      emit('click:item', {
        assignmentUserId: unref(assignmentUserId),
        playerSessionId: unref(playerSessionId),
        atomType: unref(atomType),
      });
    };

    return {
      onCardClickHandler,
    };
  },
});
