
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'PageSection',
  props: {
    header: {
      type: String as PropType<String>,
      default: '',
    },
    titleLevel: {
      type: Number,
      default: 1,
    },
    subheader: {
      type: String as PropType<String>,
      default: '',
    },
    name: {
      type: String as PropType<string>,
      default: '',
    },
  },
});
