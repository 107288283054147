
import {
  computed, defineComponent, PropType, ref, toRefs,
} from 'vue';
import { CatalogueLearningFinishedAssignment } from '@/components/tracks/CatalogueLearningFinishedAssignment';
import BaseCarousel from '@/components/ui/BaseCarousel/BaseCarousel.vue';
import { PageSection } from '@/components/tracks/PageSection';
import { IAssignment } from '@/domains/assignment';

export default defineComponent({
  name: 'CatalogueLearningFinishedAssignments',

  components: { BaseCarousel, PageSection, CatalogueLearningFinishedAssignment },

  props: {
    items: {
      type: Array as PropType<IAssignment[]>,
      default: () => [],
    },

    itemsInSlide: {
      type: Number,
      default: 4,
    },

    itemCols: {
      type: Number,
      default: 3,
    },
  },

  setup(props) {
    const { items } = toRefs(props);

    const currentPage = ref(1);

    const total = computed(() => {
      return items.value.length;
    });

    return {
      currentPage,
      total,
    };
  },
});
