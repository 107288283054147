
import Vue, { PropType } from 'vue';
import ProgramCardContinueItem from '@/components/tracks/ProgramCardsContinue/ProgramCardContinueItem.vue';
import { IContinueTrack } from '@/domains/track';

export default Vue.extend({
  name: 'ProgramCardsContinue',
  components: {
    ProgramCardContinueItem,
  },

  props: {
    items: {
      type: Array as PropType<IContinueTrack[]>,
      default: () => [],
    },
    defaultVisibleItemsCount: {
      type: Number,
      default: 4,
    },
  },

  setup(_, { emit }) {
    const onClickItemHandler = (item: IContinueTrack) => {
      emit('click:item', item);
    };

    return {
      onClickItemHandler,
    };
  },

  data() {
    return {
      visibleStartIndex: 0,
    };
  },
  computed: {
    visibleItems(): IContinueTrack[] {
      return this.items.slice(
        this.visibleStartIndex,
        this.visibleStartIndex + this.defaultVisibleItemsCount,
      );
    },

    maxIndex(): number {
      const index = (this.items.length - 1) > 0 ? (this.items.length - 1) : 0;
      return Math.trunc(index / this.defaultVisibleItemsCount) * this.defaultVisibleItemsCount;
    },
  },
  methods: {
    previewItems() {
      if (this.visibleStartIndex <= 0) {
        return;
      }

      this.visibleStartIndex -= this.defaultVisibleItemsCount;
    },

    nextItems() {
      if (this.visibleStartIndex + this.defaultVisibleItemsCount >= this.items.length) {
        return;
      }

      this.visibleStartIndex += this.defaultVisibleItemsCount;
    },
  },
});
