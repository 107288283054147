
import Vue, { PropType } from 'vue';
import CatalogueLearningProgram from '@/components/tracks/CatalogueLearningPrograms/CatalogueLearningProgram.vue';
import CardToCatalog from '@/components/tracks/CardToCatalog.vue';
import { IAtomWithAssigments } from '@/domains/track';

export default Vue.extend({
  name: 'CatalogueLearningPrograms',
  components: {
    CatalogueLearningProgram,
    CardToCatalog,
  },

  props: {
    items: {
      type: Array as PropType<IAtomWithAssigments[]>,
      default: () => [],
    },
  },
});
