
import {
  computed, defineComponent, PropType, ref, toRefs,
} from 'vue';

import { useI18n } from '@/plugins/vue-i18n';

import { IWillStudyTrack } from '@/domains/track';
import { AtomType } from '@/domains/atom';

import AtomCover from '@/components/ui/AtomCover/AtomCover.vue';

export default defineComponent({
  name: 'WillStudyItem',

  components: {
    AtomCover,
  },

  props: {
    item: {
      type: Object as PropType<IWillStudyTrack>,
      required: true,
    },
    isActiveDeleteIcon: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    const { tc } = useI18n();

    const { item } = toRefs(props);

    const options = ref({ size: '320x320' });

    const progressValue = computed(() => {
      return (item.value.trackProgress.finishedStepsCount / item.value.trackProgress.totalStepsCount) * 100;
    });
    const completedOf = computed(() => {
      const { totalStepsCount, finishedStepsCount } = item.value.trackProgress;

      return tc('pluralized.lessonsCountOf', totalStepsCount, {
        count: finishedStepsCount,
        total: totalStepsCount,
      });
    });
    const isTrack = computed(() => item.value.atomType === AtomType.TRACK);
    const progressColor = computed(() => (progressValue.value >= 100
      ? 'tt-color-green-400'
      : 'tt-color-indigo-400'));

    const deleteTrack = (track: IWillStudyTrack, event: Event) => {
      event.stopPropagation();
      emit('remove', track);
    };

    return {
      progressValue,
      completedOf,
      isTrack,
      progressColor,
      options,
      deleteTrack,
    };
  },
});
