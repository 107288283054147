
import { computed, defineComponent, PropType } from 'vue';
import { AtomType } from '@/domains/atom';
import { useI18n } from '@/plugins/vue-i18n';

export default defineComponent({
  name: 'AtomTypeChip',
  props: {
    value: {
      type: String as PropType<AtomType>,
      required: true,
    },
    dataTest: {
      type: String,
      default: 'atom-type',
    },
  },

  setup(props) {
    const { t } = useI18n();
    const atomTypeDisplaynameResolver: Record<AtomType, string> = {
      [AtomType.MULTICONTENT]: t('AtomTypeChip.lesson'),
      [AtomType.MULTILEVELTEST]: t('AtomTypeChip.test'),
      [AtomType.QUIZ]: t('AtomTypeChip.test'),
      [AtomType.SCORM]: t('AtomTypeChip.lesson'),
      [AtomType.TRACK]: t('AtomTypeChip.track'),
      [AtomType.PROGRAM]: '',
      [AtomType.UNKNOWN]: '',
    };

    const atomTypeDisplayname = computed(() => atomTypeDisplaynameResolver[props.value]);
    return { atomTypeDisplayname };
  },
});
